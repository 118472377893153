<template>
  <b-modal :id="modalid" :hide-header="true" :hide-footer="true">
    <h5>
      <span class="warning">
        <font-awesome-icon icon="exclamation-circle" />
      </span>
      Opgelet!
    </h5>
    <p class="my-4">
      {{ message }}
    </p>
    <b-button variant="primary" @click="confirm()">Bevestig</b-button>
    <b-button variant="link" @click="hide()">Annuleren</b-button>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton
  },
  props: {
    message: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    },
    modalid: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    }
  },
  methods: {
    hide: function() {
      this.$bvModal.hide(this.modalid)
    },
    confirm: function() {
      this.$emit('confirm')
    },
    cancel: function() {
      this.$emit('cancel')
    }
    //this.$refs[this.modalid].show()
  }
}
</script>
